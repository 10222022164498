// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "core-js/es";
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import App from "./App";
import router from "./router";
import { createProvider } from "./components/vue-apollo";
import { GC_USER_ID, GC_AUTH_TOKEN } from "./components/auth-settings";
import * as VueGoogleMaps from "vue2-google-maps";
import { httpEndpoint } from "./components/vue-apollo.js";
import store from "./components/store";

import jQuery from "jquery";
global.jquery = jQuery;
global.$ = jQuery;
window.$ = window.jQuery = require("jquery");

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCT4Ap0vBgg3I1JxFZE0VFTQwhgz_Omo34",
    libraries: "places",
    load: {
      region: "JP",
      language: "jp"
    }
  }
});

let userId = localStorage.getItem(GC_USER_ID);
const token = localStorage.getItem(GC_AUTH_TOKEN);

function SetPagePermission() {
  var result = $.ajax({
    type: "POST",
    url: httpEndpoint,
    contentType: "application/json",
    headers: {
      Authorization: "JWT " + token
    },
    cache: false,
    data: JSON.stringify({
      query: `query{
        me{
          authgroupsoperatorsSet{
            edges{
              node{
                authGroup{
                  authgroupspagepermissionsSet{
                    edges{
                      node{
                        pagePermission{
                          name
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`
    }),
    success: function(data) {
      // 通信の成功時 storeに権限を登録
      store.urls = [];
      if(data["errors"]) {
        localStorage.clear();
        if (process.env.NODE_ENV == "production") {
          location.replace('https://operator.legmin.co.jp/#/login');
        }else{
          location.replace('http://' + window.location.hostname + ':8080/#/login');
        }
        location.reload(true);
      }
      var pagePermissions =
        data.data.me.authgroupsoperatorsSet.edges[0].node.authGroup
          .authgroupspagepermissionsSet.edges;
      for (var index in pagePermissions) {
        var pagePermission = pagePermissions[index].node.pagePermission;
        store.urls.push(pagePermission.url);
      }
    }
  });
  return result;
}

if (token) {
  SetPagePermission().done(function() {
    new Vue({
      el: "#app",
      router,
      template: "<App/>",
      apolloProvider: createProvider(),
      data: {
        userId
      },
      components: {
        App
      }
    });
  });
} else {
  new Vue({
    el: "#app",
    router,
    template: "<App/>",
    apolloProvider: createProvider(),
    data: {
      userId
    },
    components: {
      App
    }
  });
}
