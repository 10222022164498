// // --------------------------------
// // ナビゲーションガード
// // --------------------------------

import store from "./store";

const guard = (to, from, next) => {
  for (var url in store.urls) {
    if (store.urls[url] == to.path) {
      next();
    }
  }
  next(false);
};
export default {
  guard: guard,
};
