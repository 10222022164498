import Vue from "vue";
import Router from "vue-router";
import multiguard from "vue-router-multiguard";
import VCalendar from "v-calendar";
import NavigationGuard from "../components/navigation-guard";

// Containers
const DefaultContainer = () => import("@/views/containers/DefaultContainer");

const Dashboard = () => import("@/views/pages/Dashboard");
const WorkLogPage = () => import("@/views/pages/WorkLogPage");
const PointToMovePage = () => import("@/views/pages/PointToMovePage");
const SaveMultiplePositionPage = () =>
  import("@/views/pages/SaveMultiplePositionPage");
const RosMonitoringPage = () => import("@/views/pages/RosMonitoringPage");
const Console = () => import("@/views/pages/Console");
const RosLogPage = () => import("@/views/pages/RosLogPage");
const CalendarPage = () => import("@/views/pages/CalendarPage");
const MiSetting = () => import("@/views/pages/MiSetting");
const Login = () => import("@/views/pages/Login");
const RobotPage = () => import("@/views/pages/RobotPage");
const OperatorWorkPage = () => import("@/views/pages/OperatorWorkPage");
const FieldPage = () => import("@/views/pages/FieldPage");
const CameraPage = () => import("@/views/pages/CameraPage");
const PlanPage = () => import("@/views/pages/PlanPage");
const UserManagementPage = () => import("@/views/pages/UserManagementPage");
const SetFarmer = () => import("@/views/pages/SetFarmer");
const MultiplePositionEditPage = () => import("@/views/pages/MultiplePositionEditPage");
const NotFound = () => import("@/views/pages/NotFound");
const WeatherCreatePage = () => import("@/views/pages/WeatherCreatePage");
const CameraPicturesPage = () => import("@/views/pages/CameraPicturesPage");
const FarmsCameraPictures = () => import("@/views/pages/FarmsCameraPicturesPage");



Vue.use(Router);
Vue.use(VCalendar);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      redirect: "camerapicturespage",
      name: "Home",
      component: DefaultContainer,
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard
        },
        {
          path: "worklogpage",
          name: "WorkLogPage",
          beforeEnter: multiguard([NavigationGuard.guard]),
          component: WorkLogPage
        },
        {
          path: "pointtomovepage",
          name: "PointToMovePage",
          beforeEnter: multiguard([NavigationGuard.guard]),
          component: PointToMovePage
        },
        {
          path: "savemultiplepositionpage",
          name: "SaveMultiplePositionPage",
          beforeEnter: multiguard([NavigationGuard.guard]),
          component: SaveMultiplePositionPage
        },
        {
          path: "console",
          name: "Console",
          beforeEnter: multiguard([NavigationGuard.guard]),
          component: Console
        },
        {
          path: "rosmonitoringpage",
          name: "RosMonitoringPage",
          beforeEnter: multiguard([NavigationGuard.guard]),
          component: RosMonitoringPage
        },
        {
          path: "roslogpage",
          name: "RosLogPage",
          beforeEnter: multiguard([NavigationGuard.guard]),
          component: RosLogPage
        },
        {
          path: "calendarpage",
          name: "CalendarPage",
          beforeEnter: multiguard([NavigationGuard.guard]),
          component: CalendarPage
        },
        {
          path: "robotpage",
          name: "RobotPage",
          beforeEnter: multiguard([NavigationGuard.guard]),
          component: RobotPage
        },
        {
          path: "misetting",
          name: "MiSetting",
          beforeEnter: multiguard([NavigationGuard.guard]),
          component: MiSetting
        },
        {
          path: "operatorworkpage",
          name: "OperatorWorkPage",
          beforeEnter: multiguard([NavigationGuard.guard]),
          component: OperatorWorkPage
        },
        {
          path: "fieldpage",
          name: "FieldPage",
          beforeEnter: multiguard([NavigationGuard.guard]),
          component: FieldPage
        },
        {
          path: "camerapage",
          name: "CameraPage",
          beforeEnter: multiguard([NavigationGuard.guard]),
          component: CameraPage
        },
        {
          path: "weathercreatepage",
          name: "WeatherCreatePage",
          beforeEnter: multiguard([NavigationGuard.guard]),
          component: WeatherCreatePage
        },
        {
          path: "planpage",
          name: "PlanPage",
          beforeEnter: multiguard([NavigationGuard.guard]),
          component: PlanPage
        },
        {
          path: "usermanagepage",
          name: "UserManagementPage",
          beforeEnter: multiguard([NavigationGuard.guard]),
          component: UserManagementPage
        },
        {
          path: "multiplepositioneditpage",
          name: "MultiplePositionEditPage",
          beforeEnter: multiguard([NavigationGuard.guard]),
          component: MultiplePositionEditPage
        },
        {
          path: "farmscamerapicturespage",
          name: "FarmsCameraPicturesPage",
          beforeEnter: multiguard([NavigationGuard.guard]),
          component: FarmsCameraPictures
        },
        {
          path: "camerapicturespage",
          name: "CameraPicturesPage",
          // beforeEnter: multiguard([NavigationGuard.guard]),
          component: CameraPicturesPage
        }
      ]
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    // {
    //   path: "/setfarmer",
    //   name: "SetFarmer",
    //   component: SetFarmer,
    // },
    {
      path: "*",
      name: "NotFound",
      component: NotFound
    }
  ]
});
