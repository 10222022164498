import Vue from "vue";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import {
  createApolloClient,
  restartWebsockets
} from "vue-cli-plugin-apollo/graphql-client";
import { GC_AUTH_TOKEN } from "./auth-settings";

// Install the vue plugin
Vue.use(VueApollo);

// Http endpoint
export const httpEndpoint = getEndpoint();

function getEndpoint() {
  var httpEndpoint = "http://" + window.location.hostname + ":8000/graphql/";
  if (process.env.NODE_ENV == "production") {
    httpEndpoint = "https://operator.legmin.co.jp/django/graphql/";
  }
  return httpEndpoint;
}

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,

  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  //wsEndpoint: 'ws://localhost:4000/graphql',

  // LocalStorage token
  tokenName: GC_AUTH_TOKEN,

  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,

  // Is being rendered on the server?
  ssr: false,

  // Override default cache
  cache: new InMemoryCache({
    freezeResults: false
  }),

  // link: authLink.concat(httpLink),
  getAuth: getAuth
};

function getAuth(tokenName) {
  if (typeof window !== "undefined") {
    // get the authentication token from local storage if it exists
    const token = window.localStorage.getItem(tokenName);
    // return the headers to the context so httpLink can read them
    return token ? `JWT ${token}` : "";
  }
}

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options
  });
  apolloClient.wsClient = wsClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      }
    },
    errorHandler(error) {
      // graphqlError時の処理
      console.log(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message
      );
    }
  });

  return apolloProvider;
}

export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}
